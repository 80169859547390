<template>
  <div>
    <v-container>
      <v-row class="d-flex align-center">
        <v-col cols='12'>
          <v-card-title >
            9. Разработка плана эвакуации людей при пожаре, содержание путей эвакуации
          </v-card-title>
        </v-col>
        <v-col cols="7">
          <v-card-text class="title font-weight-bold">
            Руководитель организует разработку планов эвакуации на объектах:
          </v-card-text>
          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            с массовым пребыванием людей;
          </v-card-text>
          <v-card-text class="title font-weight-light">
            <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
            с постоянными рабочими местами на этаже для 10 и более человек.
          </v-card-text>
        </v-col>
        <v-col cols="5">
          <v-card class="d-flex pa-3">
            <v-card-text class="title font-weight-light mr-5">
              На плане эвакуации обозначаются места хранения первичных средств пожаротушения.
            </v-card-text>
            <v-img
              lazy-src="img/material/evacuation-point.svg"
              max-width="100"
              src="img/material/evacuation-point.svg"
              class="ma-1"
              contain
            ></v-img>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-tabs align-with-title  color="red" grow v-model="tab">
            <v-tab>Обязанности руководителя</v-tab>
            <v-tab>Общие требования к содержанию</v-tab>
          </v-tabs>
          <v-tabs-items vertical v-model="tab">
            <v-tab-item>
              <v-card-text class="title font-weight-bold mt-2">
                При эксплуатации эвакуационных путей и выходов руководитель обеспечивает:
              </v-card-text>
              <v-row>
                <v-col cols="6">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                    <v-img
                      lazy-src="img/material/exit.svg"
                      max-width="80"
                      src="img/material/exit.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      наличие
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <span
                            class="blue lighten-4"
                            v-on="on"
                          >знаков</span>
                        </template>
                        <span class="pa-5 subtitle-1 pl-0">обозначающих в том числе пути эвакуации и эвакуационные выходы, <br>места размещения аварийно-спасательных устройств и снаряжения, <br>стоянки мобильных средств пожаротушения</span>
                      </v-tooltip>
                       пожарной безопасности
                    </v-card-text>
                  </v-card>
                                      <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                    <v-img
                      lazy-src="img/material/exit-width.svg"
                      max-width="80"
                      src="img/material/exit-width.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <span
                            class="blue lighten-4"
                            v-on="on"
                          >ширину</span>
                        </template>
                        <span class="pa-5 subtitle-1 pl-0">установленную требованиями ПБ</span>
                      </v-tooltip>
                       путей эвакуации и эвакуационных выходов при расстановке
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <span
                            class="blue lighten-4"
                            v-on="on"
                          >оборудования</span>
                        </template>
                        <span class="pa-5 subtitle-1 pl-0">технического, выставочного и другого</span>
                      </v-tooltip>

                    </v-card-text>
                  </v-card>
                  <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    flat

                    >
                    <v-img
                      lazy-src="img/material/document-shield.svg"
                      max-width="80"
                      src="img/material/document-shield.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      соблюдение проектных решений и требований
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <span
                            class="blue lighten-4"
                            v-on="on"
                          >нормативных документов по ТБ</span>
                        </template>
                        <span class="pa-5 subtitle-1 pl-0">в том числе по освещенности, количеству, размерам и объемно-планировочным<br> решениям эвакуационных путей и выходов, а также по наличию на путях эвакуации знаков<br> пожарной безопасности</span>
                      </v-tooltip>
                       в соответствии с требованиями законодательства

                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                    >
                    <v-img
                      lazy-src="img/material/door-lock.svg"
                      max-width="80"
                      src="img/material/door-lock.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      наличие и исправное состояние приспособлений для самозакрывания на противопожарных дверях и воротах, на дверях лестничных клеток, эвакуационных выходов
                    </v-card-text>
                  </v-card>
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat
                      >
                    <v-img
                      lazy-src="img/material/fireman.svg"
                      max-width="80"
                      src="img/material/fireman.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      доступ подразделениям пожарной охраны в любые помещения для эвакуации и спасения людей, ограничения распространения, локализации и тушения пожара
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
              <v-col cols="6">
                    <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                    <v-img
                      lazy-src="img/material/hammer.svg"
                      max-width="80"
                      src="img/material/hammer.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      покрытия полов (ковры, ковровые дорожки) надежно крепятся к полу
                    </v-card-text>
                  </v-card>
                                      <v-card
                      class="pa-2 d-flex justify-center align-center"
                      full-width
                      flat

                      >
                    <v-img
                      lazy-src="img/material/door-chain.svg"
                      max-width="80"
                      src="img/material/door-chain.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      запоры на дверях эвакуационных выходов обеспечивают их свободное открывание изнутри без ключа, а
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <span
                            class="blue lighten-4"
                            v-on="on"
                          >объекты</span>
                        </template>
                        <span class="pa-5 subtitle-1 pl-0">охрана, обеспечение безопасности</span>
                      </v-tooltip>
                       с особым режимом содержания помещений обеспечиваются
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <span
                            class="blue lighten-4"
                            v-on="on"
                          >автоматическим</span>
                        </template>
                        <span class="pa-5 subtitle-1 pl-0">по сигналу систем противопожарной защиты здания или дистанционно сотрудником (работником), осуществляющим круглосуточную охрану</span>
                      </v-tooltip>
                        открыванием запоров
                    </v-card-text>
                  </v-card>
                  <v-card
                    class="pa-2 d-flex justify-center align-center"
                    full-width
                    flat

                    >
                    <v-img
                      lazy-src="img/material/low-energy.svg"
                      max-width="80"
                      src="img/material/low-energy.svg"
                      class="ma-1"
                    ></v-img>
                    <v-spacer></v-spacer>
                    <v-card-text class="title font-weight-light">
                      эвакуационное освещение работает круглосуточного или
                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <span
                            class="blue lighten-4"
                            v-on="on"
                          >выключается автоматически</span>
                        </template>
                        <span class="pa-5 subtitle-1 pl-0">в зрительных, демонстрационных и выставочных залах знаки пожарной безопасности с автономным питанием и от электросети могут включаться только во время мероприятий с пребыванием людей</span>
                      </v-tooltip>
                       при прекращении электропитания, а светильники аварийного освещения отличаются знаками или окраской
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="6">
                  <v-card flat class=" ma-5">
                    <v-card-title>
                      Эвакуационные пути не должны включать лифты и эскалаторы, а также участи, ведущие:
                    </v-card-title>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      через коридоры с выходами из лифтовых шахт, через лифтовые холлы и тамбуры перед лифтами, если

                      <v-tooltip bottom color="red">
                        <template v-slot:activator="{ on }">
                          <span
                            class="blue lighten-4"
                            v-on="on"
                          >ограждающие конструкции</span>
                        </template>
                        <span class="pa-5 subtitle-1 pl-0">включая двери шахт лифтов</span>
                      </v-tooltip>
                       шахт лифтов, не отвечают требованиям, предъявляемым к противопожарным преградам;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      через «проходные» лестничные клетки, когда площадка лестничной клетки – часть коридора, а также через помещение, в котором расположена лестница 2-го типа, не являющаяся эвакуационной;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      по кровле зданий, за исключением эксплуатируемой кровли или специально оборудованного участка;
                    </v-card-text>
                    <v-card-text class="title font-weight-light">
                      <v-icon color="red" class="mr-3">mdi-check-all</v-icon>
                      по лестницам 2 типа, соединяющим более 2 этажей, а также ведущим из подвалов и цокольных этажей.
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <Hint></Hint>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  data: () => ({
    tab: null
  }),
  components: {
    Hint
  }
}
</script>
<style lang="less" scoped>
.ptb {
  background-color: rgb(243, 130, 130);
}
</style>
